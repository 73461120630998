@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif !important;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999; /* Ensure the loader is on top of other elements*/
} 




/* primary color: 4A0404 */

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4A0404;
  height: 100px;
  padding: 0 20px;
  color: white;
}

.Logo {
  font-size: 24px;
  font-weight: 900;
  margin-left: 30px;
}

.Menu ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-right: 30px;
}

.Menu li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.MainContent {
  display: flex;
  flex: 1;
  /* background-color: #f5f5f5; */
}

.Sidebar {
  flex: 1;
  padding-right: 20px;
  background-color: #4A0404;
  color: white;
}

.Sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.Sidebar li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 30px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.Sidebar li:hover {
  background-color: white;
  color: #4A0404;
}

.Sidebar li span {
  margin-left: 10px;
}


.CardList {
  flex: 5;
  gap: 20px;
  padding: 10px;

  padding-left: 20px;
}

.Card {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.Card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ... other styles ... */

.CustomCard {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  max-width: 550px;
}

.CustomCard img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.subscriptionThumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.CustomCard h2 {
  margin: 0;
  flex: 1;
}

.CustomCard button {
  background-color: #4A0404;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  float: right !important;
  margin-left: 10px;
}

button {
  background-color: #4A0404;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  margin-left: 10px;
}

.subscribe {
  background-color: #4A0404;
  color: white;
  border: none;
  padding: 10px 40px;
  cursor: pointer;
  border-radius: 3px;
  width: 160px;
  margin-left: 0 !important;

}

.CustomCard button:hover {
  background-color: #0056b3;
}



/* custom */
.subscriptionHome{
  display: block !important;
  width: 100% !important;
}

.HSeparator{
  margin: 30px 0;
  color: #000 !important;
}

.TableContainer {
  width: 1050px;
  overflow-x: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: 20px;
}

.Table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid black;
}

.Table th,
.Table td {
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid grey;
}

.Table th {
  background-color: #4A0404;
  color: white;
}

.Table tbody tr {
  background-color: #f5f5f5;
}

.Table tbody tr:nth-child(odd) {
  background-color: white;
}

.Breadcrumbs {
  display: flex;
  align-items: center;
}

.Breadcrumb {
  color: #4A0404;
  text-decoration: none;
  margin-right: 5px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.Breadcrumb:hover {
  color: #0056b3;
}

.BreadcrumbSeparator {
  color: #ccc;
  margin: 0 5px;
}

.ActiveBreadcrumb {
  color: gray;
}

/* catalog item */

.Column {
  flex: 4;
  margin-top: 20px;
}

.Column1 {
  background-color: #f5f5f5;
}

/* .Column2 {
  background-color: #d9d9d9;
} */

.SubCard {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.CardTitle {
  font-weight: bold;
  margin-bottom: 10px;
}


.CardDescription {
  margin-bottom: 10px;
}

.CardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CardLink {
  color: #4A0404;
  cursor: pointer;
}

.CardText {
  color: #666;
}

.BigCard {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
}

.BigCardTitle {
  font-weight: bold;
  margin: 10px 0;
}

.BigCardButton {
  background-color: #4A0404;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
}

.BigCardButton:hover {
  background-color: #0056b3;
}

.noSubMsg{
  margin-left: 5px;
  margin-top: 20px;
}

.LoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginBox {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}

.LoginBox h2 {
  margin-bottom: 10px;
  font-weight: bold;
}

.LoginBox p {
  margin-bottom: 20px;
  color: #888;
}

.GoogleButton {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}

.GoogleButton img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.GoogleButton:hover {
  background-color: #357ae8;
}


.RadioButtonContainer {
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.Description {
  margin-bottom: 20px;
}

.Description h2 {
  font-weight: bold;
  margin-bottom: 10px;
}

.RadioButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.RadioButton {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 400 !important;
}

.RadioButton input {
  margin-right: 8px;
}

.RadioButton label {
  font-size: 14px;
  color: #555;
  cursor: pointer;
  font-weight: 400 !important;
}

/* .RadioButton input[type='radio']:checked + label {
  font-weight: bold;
} */




.tsContainer{
  display: inline;
}


.trainingPieContainer{
  float: right;
  /* margin-right: 150px; */
  margin-top: -130px;
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: #eee;
  max-width: 400px;
}

.tsPieHeading{
  text-align: center;
  margin-bottom: 40px;
}

.tsHr{
  border: 0.3px solid #aaa;
  margin: 30px 10px !important;
}

.tsOpenSessionBtn{
  text-align: center;
  width: 300px;
  height: 40px;
  font-size: 16px;
}

.tsOpenSessionBtnDel{
  text-align: center;
  width: 300px;
  height: 40px;
  font-size: 16px;
  margin-top: 10px;
  background-color: transparent;
  color: red;
  border: 1px solid red;
}

.cntr {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* setup training session code */
.setup-training-container {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.box {
  max-width: 300px;
  padding: 15px;
  border: 1px solid #ccc;
}

.checklist-box {
  margin-right: 60px;
}

/* Additional styling for checklist items */
.checklist-item {
  margin-bottom: 10px;
}

/* .slider-box {
  Add additional styling for the slider box if needed
} */

/* Vertical spacing between rows */
.spacing {
  width: 60px;
}


/* Add additional styling as needed */


.side-nav {
  background-color: #4A0404 !important;
  margin-top: 64px !important;
}